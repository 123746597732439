.regenerate-ideas-button {
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 999px;
  background: var(--colour-system-colour-purple, #7948f6);
  color: var(--contrast-bg-1, var(--colour-system-system-light-1, #fff));
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 153.846% */

  &--disabled {
    background: var(--colour-system-system-dark-10, rgba(0, 0, 0, 0.1));
  }
}
