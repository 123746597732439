#shorts {
  width: 262px;
  height: 370px;
  position: relative;

  .shorts {
    &-main {
      position: absolute;
      bottom: 0;
      display: flex;
      padding: var(--numbers-margin-low, 16px);
      flex-direction: column;
      align-items: flex-start;
      gap: var(--numbers-margin-low, 16px);
      align-self: stretch;
      background: rgba(0, 0, 0, 0.24);
      backdrop-filter: blur(8.5px);
      border-radius: 15px;
    }

    &__image {
      max-width: 100%;
      height: 100%;
      border-radius: 15px;
    }

    &__title {
      color: var(--colour-system-system-light-1, #fff);
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px; /* 128.571% */
      letter-spacing: -0.14px;
      align-self: stretch;
    }

    &__details {
      display: flex;
      align-items: flex-start;
      gap: 23px;
      color: var(--text-colours-l-1, var(--colour-system-system-light-1, #fff));
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 133.333% */
    }
  }
}
