#game-1-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 56px;
  margin-top: 4rem;

  .header-game-1 {
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 45px;
    font-style: normal;
    font-weight: 700;
    line-height: 80px; /* 142.857% */
    letter-spacing: -0.56px;
  }

  .cards-display {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 32px;
    overflow: hidden;
    flex-wrap: wrap;
  }

  @media (max-width: 800px) {
    .header-game-1 {
      width: 90%;
      font-size: 35px;
      line-height: normal;
    }
  }

  @media (max-width: 500px) {
    .header-game-1 {
      width: 90%;
      font-size: 30px;
      line-height: normal;
    }

    .cards-display {
      flex-direction: column;
    }
  }
}
