.app-main {
  min-height: 100vh;
  width: 100%;
  background: radial-gradient(
    77.24% 72.1% at 50% 50%,
    #895aff 0%,
    #6a40d4 100%
  );
  padding-bottom: 5rem;
  position: relative;
}

@media (max-width: 500px) {
  .img {
    width: auto;
    max-width: 50%;
    height: auto;
  }
}
