@media (min-height: 800px) {
  .custom-max-height {
    max-height: 100vh;
  }
}

@media (min-height: 900px) {
  .custom-max-height {
    max-height: 90vh;
  }
}
