@media (max-width: 500px) {
  .settings-modal-content {
    display: flex;
    flex-direction: column;
  }

  .settings-tabs {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: -2rem;
  }

  .settings-content-width {
    width: 100%;
  }
}
