#modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
}

#payment {
  display: inline-flex;
  padding: 64px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  border-radius: 16px;
  background: #fff;
  position: absolute;
  top: 50%; /* Move the modal down by 50% of its height */
  transform: translateY(-50%);

  .payment-header {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.32px;
  }

  .payment__panel {
    display: flex;
    padding: 32px;
    width: 411px;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    flex: 1 0 0;
    border-radius: 16px;
    border: 2px solid var(--colours-accent, #7948f6);
    background: var(--colour-system-system-light-1, #fff);

    &__row1 {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      align-self: stretch;

      &__header {
        color: var(
          --text-colours-d-1,
          var(--colour-system-system-dark-1, #26242d)
        );
        font-family: Inter;
        font-size: 40px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.4px;
      }

      &__desc {
        color: var(
          --text-colours-d-2,
          var(--colour-system-system-dark-2, #5c566b)
        );
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.14px;
      }
    }
  }

  .payment__divider {
    width: 100%;
    height: 1px;
    background: var(--colour-system-system-dark-10, rgba(0, 0, 0, 0.1));
  }

  .payment__points {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;

    &__point {
      color: var(
        --text-colours-d-1,
        var(--colour-system-system-dark-1, #26242d)
      );
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 114.286% */
      letter-spacing: -0.14px;
    }
  }

  .payment__payoptions {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--numbers-margin-low, 16px);
    align-self: stretch;

    &__wrapper {
      display: flex;
      align-items: baseline;
      gap: 8px;
      align-self: stretch;
    }

    &__discount {
      display: flex;
      padding: 4px 8px;
      align-items: baseline;
      gap: 10px;
      border-radius: var(--numbers-crcr-low, 8px);
      background: var(
        --colour-system-colour-purple-20,
        rgba(121, 72, 246, 0.2)
      );

      &__text {
        color: #000;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.14px;
      }
    }
  }

  .payment__price {
    color: var(--text-colours-d-1, var(--colour-system-system-dark-1, #26242d));
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.32px;
  }
}

.payment__button {
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 999px;
  color: white;
  background: var(--colour-system-colour-purple, #7948f6);
}

#payment-success {
  display: inline-flex;
  padding: 56px;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  border-radius: 16px;
  background: var(--colour-system-system-light-1, #fff);
  background: #fff;
  position: absolute;
  top: 50%; /* Move the modal down by 50% of its height */
  transform: translateY(-50%);
}

.payment-success {
  &__image {
    width: 322px;
    height: 322px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--numbers-margin-medium, 24px);
  }

  &__success-message {
    color: var(--text-colours-d-1, var(--colour-system-system-dark-1, #26242d));
    font-family: Inter;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.4px;
  }

  &__success-desc {
    color: var(--text-colours-d-2, var(--colour-system-system-dark-2, #5c566b));
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.16px;
  }
}
