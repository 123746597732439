.remaining-content-ideas {
  display: flex;
  padding: 8px 12px 8px var(--numbers-margin-low, 16px);
  align-items: center;
  gap: 4px;
  border-radius: 22px;
  background: rgba(121, 72, 246, 0.2);
  color: #7948f6;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
}
