.card {
  display: flex;
  width: 274.25px;
  padding: 48px 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  border-radius: 16px;
  background: #fff;
  cursor: pointer;
  z-index: 2;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.gap-24 {
  gap: 24px;
}

.gap-12 {
  gap: 12px;
}

.details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.name {
  color: var(--text-d-1, var(--colour-system-system-dark-1, #26242d));
  text-align: center;
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.22px;
}

.subs {
  color: var(--text-d-1, var(--colour-system-system-dark-1, #26242d));
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.12px;
}

.type {
  display: flex;
  padding: 6px 14px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 999px;
  background: var(--colour-system-system-light-2, #f2f4fa);
}

.desc {
  color: var(--text-d-2, var(--colour-system-system-dark-2, #5c566b));
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.12px;
}
